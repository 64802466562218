import React, {useEffect, useRef} from 'react';
import './App.css';

function App() {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    const newVideo = videoRef.current;

    if (newVideo) {
      newVideo.addEventListener('load', function(){
        newVideo.addEventListener('ended', function() {
          this.currentTime = 0;
          this.play();
        }, false);

        newVideo.play();
      });
    }
  }, []);

  return (
      <div className="App">
        <header className="App-header">
          <div className="app-tag">
            OPENMIND
          </div>

          <div className="app-tag">
            4 вина
          </div>
        </header>

        <div className="app-container">
          <div className="app-description app-description-adaptive text-left text-gray">
            <p>
              Что если, вы могли бы познакомиться с новым и непривычным для вас миром, не выезжая из своего города? Именно такую услугу предоставляет наша event компания.
            </p>
          </div>

          <div className="app-video">
            <iframe
                width="100%"
                height="120%"
                src="https://www.youtube.com/embed/75ze0TZ_vWo?si=JsVSGOY8gdDSY_S4"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media"
                allowFullScreen
            />
          </div>

          <div className="app-content">
            <div className="app-description text-left text-gray">
              <p>
                Что если, вы могли бы познакомиться с новым и непривычным для вас миром, не выезжая из своего города? Именно такую услугу предоставляет наша event компания.
              </p>
            </div>

            <div className="app-titles">
              <h4 className="text-peach">
                Презентация Астраханской винодельни
              </h4>

              <h3>
                Константина Егорова
              </h3>

              <h1>
                ул. Ахматовская 3 <br/>
                5 ноября, 17:00
              </h1>

              <a href={'https://t.me/eventopenmind'} className="app-button">
                Забронировать
              </a>
            </div>

            <div className="app-description text-right text-gray">
              <p>
                Наша event-компания проводит презентацию Первой Астраханской винодельни. Мы предлагаем вам по-новому взглянуть на Астрахань, и воочию убедиться, что она не заканчивается на рыбе и лотосе.
              </p>
            </div>
          </div>
        </div>
      </div>
  );
}

export default App;
